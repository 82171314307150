@import "../../style/index.scss";
.home {
  width: 100%;
  // height: 90.91rem;
  color: #fff;
  background: url(../../assets/mobile/home_bg.png) no-repeat top center;
  background-size: 100% auto;
  @media (min-width:768px) {
    background: url(../../assets/img/home_bg.png) no-repeat top center;
  background-size: contain;

  }
}

.title{
  text-align: center;
  text-transform: uppercase;
  span{
    background: linear-gradient(to bottom, #fff ,#458EDF );
    -webkit-background-clip: text;
    color: transparent;
    font-family:BBM;
    position: relative;
    font-size: .62rem;
    line-height: 1.8;
    display: inline-block;
    &::after{
      content: '';
      width: 100px;
      height: 4px;
      position: absolute;
      background-color: #2B93FF;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,50%);
      border-radius: .2rem;
    }
  }
  @media (min-width:768px) {
    span{
      font-size: .48rem;
      line-height: 1.8;
      &::after{
        width: 100px;
        height: 9px;
      }
    }
  }
}

.header{
  background-image: linear-gradient(180deg, rgba(0,0,0,.3), rgba(61,99,125,.15));
  -webkit-backdrop-filter: blur(10px );
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 9999;
}

.banner{
  padding: 1.5rem 0;
  .container{
    background: url(../../assets/mobile/banner_bg.png) no-repeat bottom center;
    background-size: 100% 100%;
    padding-bottom: 2.4rem;
  //   min-height: 13.5rem;
  // padding: 1rem 0;

    // padding-top: .52rem;
    // padding-bottom: .3rem;
    .wrap{
      // width: 15.4rem;
      // background-image: linear-gradient(45deg, rgba(255,255,255,.08), rgba(255,255,255,0));
      // -webkit-backdrop-filter: blur(10px );
      // backdrop-filter: blur(10px);
      // padding-top: 1rem;
      // padding-bottom: 1.23rem;
      // padding-right: 4.25rem;
      // position: relative;
      p{
        margin-top: .4rem;
      }
      .coinList{
        margin-top: .4rem;
        .item{
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
          margin-bottom: .3rem;
          .img{
            width: 80/$base;
            margin-right: .15rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .cont{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .title{
              font-size: 24/$base;
              color: #FFFEFEFC;
              line-height: 1.2;
            }
            .addr{
              font-size: 24/$base;
              ine-height: 1.2;
            }

          }
        }
      }
      .btn-wrap{
        margin-top: 1.21rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        ::nth-last-child(1)first-child{
          margin-right: 0;
        }
        :nth-last-child(2):first-child{
          margin-right: 0;
          margin-bottom: .4rem;
        }
        // a{
        //   max-width: 4.2rem;
        // }
      }
    }
  }

  @media (min-width:768px) {
    padding: .5rem 0;
  .container{
    background: url(../../assets/img/banner_bg.png) no-repeat top center;
    background-size: 100% 100%;
    padding-top: .52rem;
    padding-bottom: .3rem;
    .wrap{
      width: 15.4rem;
      background-image: linear-gradient(45deg, rgba(255,255,255,.08), rgba(255,255,255,0));
      -webkit-backdrop-filter: blur(10px );
      backdrop-filter: blur(10px);
      padding-top: 1rem;
      padding-bottom: 1.23rem;
      padding-right: 4.25rem;
      position: relative;
      p{
        margin-top: .23rem;
      }
      .btn-wrap{
        margin-top: 1.21rem;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        ::nth-last-child(1)first-child{
          margin-right: 0;
        }
        :nth-last-child(2):first-child{
          margin-right: .5rem;
          margin-bottom: 0;
        }
      }
      .right{
        position: absolute;
        right: -3rem;
          top: -.2rem;
        .img{
          width: 7.13rem;
          
        }
      }
    }
  }
  }
}


.tokenomics{
  .wrap{
    margin-top: .6rem;
  }
  .info{
    padding-top: 1.2rem;
    .item{
      margin-bottom: .8rem;
      .tit{
        font-size: .42rem;
        line-height: 1.4;
        font-family: AVB;
        margin-bottom: .4rem;
      }
      .num{
        font-family: BBH;
        font-size: .8rem;
        line-height: 1.2;
        color: #2B93FF;
      }
      ul{
        padding: .24rem 0;
        padding-inline-start: .5rem;
        background-image: linear-gradient(30deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
        border: .001rem solid #979797;
        border-radius: .08rem;
        -webkit-backdrop-filter: blur(10px );
        backdrop-filter: blur(10px);
      
        li{
          list-style: url(../../assets/img/m_point.svg);
          font-size: .32rem;
          line-height: 1.6;
          color: rgba(255, 255, 255, 0.8);
          
          p{
            display: inline-block;
            padding-left: .2rem;
          }
        }
      }
    }
  }

  @media (min-width:768px) {
    .info{
      padding-left: 1.92rem;
      padding-top: .34rem;
      .item{
        margin-bottom: .44rem;
        .tit{
          font-size: .32rem;
          line-height: 1.4;
          font-family: AVB;
          margin-bottom: .15rem;
        }
        .num{
          font-family: BBH;
          font-size: .6rem;
          line-height: 1.2;
          color: #2B93FF;
        }
        ul{
          padding: .16rem 0;
          padding-inline-start: 30px;
          background-image: linear-gradient(30deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
          border: .01rem solid #979797;
          border-radius: .08rem;
          -webkit-backdrop-filter: blur(10px );
          backdrop-filter: blur(10px);
        
          li{
            list-style: url(../../assets/img/point.svg);
            font-size: .24rem;
            line-height: 1.6;
            color: rgba(255, 255, 255, 0.8);
            p{
              display: inline-block;
              padding-left: .15rem;
            }
          }
        }
      }
    }
  }
}

.NFT{
  .container{
    padding-top: 4.2rem;
    margin-top: -2rem;
    background: url(../../assets/mobile/nft_bg.png) no-repeat top center;
    background-size: 100%;
    padding-bottom: 2rem;
    .imgBOx{
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: .72rem;
      margin-bottom: .74rem;
      .item{
        padding: 0 .1rem;
        &.item2{
          .pic{
            width: 3.8rem;
          }
        }
        &.item1{
          .pic{
            width: 4.8rem;
          }
        }
        .version{
          line-height: 1;
          margin-bottom: .2rem;
          span{
            // background-color: #FFC75C;
            border-radius: .08rem;
            color: rgb(0, 0, 0);
            font-family: AVH;
            padding: .04rem .06rem;
            font-size: .32rem;
          }
        }
      }
    }
  }
  @media (min-width:768px) {
    .container{
      padding-top: .4rem;
      margin-top: 0;
      background: url(../../assets/img/nft_bg.png) no-repeat top center;
      background-size: 100%;
      padding-bottom: .4rem;
      .imgBOx{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: .72rem;
        margin-bottom: .74rem;
        .item{
          padding: 0 .38rem;
          &.item2{
            .pic{
              width: 3.5rem;
            }
          }
          &.item1{
            .pic{
              width: 5.7rem;
            }
            .btn-wrap{
              margin-top: .4rem;
            }
          }
          .version{
            line-height: 1;
            margin-bottom: .2rem;
            span{
              // background-color: #FFC75C;
              border-radius: .08rem;
              color: rgb(0, 0, 0);
              font-family: AVH;
              padding: .04rem .06rem;
              font-size: .2rem;
            }
          }
        }
      }
    }
  }
}

.team{
  margin-top: .5rem;
  .wrap{
    .timeList{
      background: url(../../assets/mobile/team_bg.png) no-repeat top center;
      background-size: 100% 100%;
      padding: 0;
      padding-top: .2rem;
      padding-bottom: .44rem;
      margin-top: 1.2rem;
      .item{
        margin: .55rem 0;
        img{
          width: 2.02rem;
          min-width: 120px;
        }
        .name{
          font-size: .4rem;
          line-height: 1.8;
        }
        p{
          font-size: .24rem;
          line-height: 1.2;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  @media (min-width:768px) {
    margin-top: .5rem;
    .wrap{
      .timeList{
        background: url(../../assets/img/team_bg.png) no-repeat top center;
        background-size: 100% 100%;
        padding: .84rem;
        padding-top: .29rem;
        padding-bottom: .44rem;
        margin-top: .1rem;
        .item{
          margin: .55rem 0;
          img{
            width: 2.02rem;
            min-width: 120px;
          }
          .name{
            font-size: .4rem;
            line-height: 1.8;
          }
          p{
            font-size: .24rem;
            line-height: 1.2;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }
}

.roadmap{
  .wrap{
    background: url(../../assets/mobile/roadmap_bg.png) no-repeat center;
    background-size: 100%;
    background-position-y: 3%;
    padding-top: 1.1rem;
    .list{
      margin: 0 -0.4rem;
      .item{
        padding: .4rem;
        &:first-child{
          ul{
            li{
              list-style: url(../../assets/mobile/m_point_y.svg);
              
            }
          }
        }
        .version{
          span{
            background-color: #FFC75C;
            border-radius: .08rem;
            color: rgb(0, 0, 0);
            font-family: AVH;
            padding: .04rem .06rem;
            font-size: .32rem;
          }
        }
        .date{
          font-size: .46rem;
          line-height: 1.6;
          margin: .1rem 0;
        }
        ul{
          padding: .2rem 0;
          padding-inline-start: .5rem;
          background-image: linear-gradient(30deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
          // border: .01rem solid #979797;
          border-radius: .08rem;
          -webkit-backdrop-filter: blur(10px );
          backdrop-filter: blur(10px);
        
          li{
            list-style: url(../../assets/img/m_point.svg);
            font-size: .32rem;
            line-height: 2;
            color: rgba(255, 255, 255, 0.8);
            p{
              display: inline-block;
              padding-left: .2rem;
            }
          }
        }
      }
    }
   
  }

  @media (min-width:768px) {
    .wrap{
      background: url(../../assets/img/roadmap_bg.png) no-repeat center;
      background-size: 100% 5.45rem;
      background-position-y: 3%;
      padding-top: 1.1rem;
      .list{
        margin: 0 -0.4rem;
        .item{
          padding: .4rem;
          &:first-child{
            ul{
              li{
                list-style: url(../../assets/img/point_y.svg);
              }
            }
          }
          .version{
            span{
              background-color: #FFC75C;
              border-radius: .08rem;
              color: rgb(0, 0, 0);
              font-family: AVH;
              padding: .04rem .06rem;
              font-size: .2rem;
            }
          }
          .date{
            font-size: .32rem;
            line-height: 1.6;
            margin: .1rem 0;
          }
          ul{
            padding: .2rem 0;
            padding-inline-start: .3rem;
            // background-image: linear-gradient(30deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0));
            // border: .01rem solid #979797;
            // border-radius: .08rem;
            // -webkit-backdrop-filter: blur(10px );
            // backdrop-filter: blur(10px);
          
            li{
              list-style: url(../../assets/img/point.svg);
              font-size: .24rem;
              line-height: 1.6;
              color: rgba(255, 255, 255, 0.8);
              p{
                display: inline-block;
                padding-left: .15rem;
              }
            }
          }
        }
      }
     
    }
  }
}

.Community{
  background: url(../../assets/mobile/community_bg.png) no-repeat top center;
  background-size: 100%;
  padding-bottom: 1rem;
  .wrap{
    padding-top: .7rem;
    .left{
      width: 5.12rem;
    }
    .right{
      padding-left: 0rem;
      margin-top: .4rem;
      p{
        font-size: .38rem;
        line-height: 1.4;
      }
      .socialgroup{
        font-size: .42rem;
        line-height: 1.6;
        background-color: rgba(63, 140, 255, 0.15);
        padding: 0.24rem 0.38rem;
        border-radius: .8rem;
        margin-top: .8rem;
        .btn_group{
          a{
            width: .8rem;
            margin: 0 .2rem;

          }
          margin: 0 -.14rem;

        }
      }
    }
  }

  @media (min-width:768px) {
    background: url(../../assets/img/Community_bg.png) no-repeat top center;
    background-size: 100%;
    padding-bottom: 1rem;
    .wrap{
      padding-top: .7rem;
      .left{
        width: 5.12rem;
      }
      .right{
        padding-left: 1.5rem;
        margin-top: 0;
        p{
          font-size: .38rem;
          line-height: 1.4;
        }
        .socialgroup{
          font-size: .24rem;
          line-height: 1.4;
          background-color: rgba(63, 140, 255, 0.15);
          padding: .18rem .3rem;
          border-radius: .8rem;
          margin-top: .8rem;
          .btn_group{
            a{
              width: .6rem;
              margin: 0 .14rem;
  
            }
            margin: 0 -.14rem;
  
          }
        }
      }
    }
  }
}

.backers{
  .wrap{
    padding: 0 .40rem;
    margin-top: .95rem;
    padding-bottom: 1.98rem;
    .backersList{
      margin: 0 -0.9rem;
      .item{
        padding: .35rem .9rem;
      }
    }
  }
  @media (min-width:768px) {
    .wrap{
      padding: 0 2.2rem;
      margin-top: .95rem;
      padding-bottom: 1.98rem;
      .backersList{
        margin: 0 -0.9rem;
        .item{
          padding: .35rem .9rem;
        }
      }
    }
  }
}

